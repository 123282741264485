import * as React from "react";
import type { GlobalProvider } from "@ladle/react";
import "../node_modules/@relume_io/relume-tailwind/dist/styles.css";
import "./styles.css";

const apiBaseUrl = "https://apis.relume.io/v1/preview";

export const Provider: GlobalProvider = ({ children, globalState, storyMeta }) => (
  <>
    {globalState.mode === "full" && storyMeta && (
      <a
        className="webflow-link"
        target="_blank"
        rel="noopener"
        href={`${apiBaseUrl}/${storyMeta.apiPath}`}
      >
        Webflow Version
      </a>
    )}
    {children}
  </>
);
